var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_vm.organizationType != 'Individu' ? _c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white"
  }, [_vm._v(_vm._s(_vm.$t("org.add-organization")))])]) : _vm._e(), _vm.organizationType == 'Individu' ? _c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white"
  }, [_vm._v(_vm._s(_vm.$t("org.add-individu")))])]) : _vm._e()])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.onSubmit($event);
      }
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-6",
    class: {
      'has-error': _vm.$v.form.name.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "name"
    }
  }, [_vm._v(_vm._s(_vm.$t("name")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.name.$model,
      expression: "$v.form.name.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "name"
    },
    domProps: {
      "value": _vm.$v.form.name.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.name, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.name.$error && !_vm.$v.form.name.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("name")) + " " + _vm._s(_vm.organizationType) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group col-md-6",
    class: {
      'has-error': _vm.$v.form.short_name.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "short_name"
    }
  }, [_vm._v(_vm._s(_vm.$t("shortname")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.short_name.$model,
      expression: "$v.form.short_name.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "short_name"
    },
    domProps: {
      "value": _vm.$v.form.short_name.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.short_name, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.short_name.$error && !_vm.$v.form.short_name.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("shortname")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _vm.organizationType != 'Awaris' ? _c('div', {
    staticClass: "form-group col-md-6",
    class: {
      'has-error': _vm.$v.form.code.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "code"
    }
  }, [_vm._v(_vm._s(_vm.$t("code")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.code.$model,
      expression: "$v.form.code.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "code"
    },
    domProps: {
      "value": _vm.$v.form.code.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.code, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.code.$error && !_vm.$v.form.code.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("code")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]) : _vm._e(), _vm.organizationType != 'Awaris' && _vm.organizationType != 'Individu' ? _c('div', {
    staticClass: "form-group col-md-6",
    class: {
      'has-error': _vm.$v.form.account_type.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "account_type"
    }
  }, [_vm._v(_vm._s(_vm.$t("account-type")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.account_type.$model,
      expression: "$v.form.account_type.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": "account_type"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.form.account_type, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.account")) + " --")]), _c('option', {
    attrs: {
      "value": "Biasa"
    }
  }, [_vm._v(_vm._s(_vm.$t("normal")))]), _c('option', {
    attrs: {
      "value": "Financial"
    }
  }, [_vm._v(_vm._s(_vm.$t("financial")))]), _c('option', {
    attrs: {
      "value": "Non-financial"
    }
  }, [_vm._v(_vm._s(_vm.$t("non-financial")))])]), _vm.$v.form.account_type.$error && !_vm.$v.form.account_type.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("account-type")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "form-group col-md-6",
    class: {
      'has-error': _vm.$v.form.address.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "address"
    }
  }, [_vm._v(_vm._s(_vm.$t("address")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.address.$model,
      expression: "$v.form.address.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "address"
    },
    domProps: {
      "value": _vm.$v.form.address.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.address, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.address.$error && !_vm.$v.form.address.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("address")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group col-md-6",
    class: {
      'has-error': _vm.$v.form.postcode.$error
    }
  }, [_c('label', {
    staticClass: "d-block",
    attrs: {
      "for": "postcode"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("postcode")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('span', {
    staticStyle: {
      "text-decoration": "underline",
      "font-size": "11px",
      "color": "#333"
    },
    on: {
      "click": _vm.openpostcode
    }
  }, [_vm._v(_vm._s(_vm.$t("add-new")))])])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.lazy.trim",
      value: _vm.$v.form.postcode.$model,
      expression: "$v.form.postcode.$model",
      modifiers: {
        "lazy": true,
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number",
      "id": "postcode"
    },
    domProps: {
      "value": _vm.$v.form.postcode.$model
    },
    on: {
      "blur": [function ($event) {
        return _vm.getPostcode(1);
      }, function ($event) {
        return _vm.$forceUpdate();
      }],
      "change": function ($event) {
        _vm.$set(_vm.$v.form.postcode, "$model", $event.target.value.trim());
      }
    }
  }), _vm.$v.form.postcode.$error && !_vm.$v.form.postcode.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.postcode")) + " ")]) : _vm._e(), _vm.$v.form.postcode.$error ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.postcode-number")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group col-md-6",
    class: {
      'has-error': _vm.$v.form.city.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "city"
    }
  }, [_vm._v(_vm._s(_vm.$t("city")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.city.$model,
      expression: "$v.form.city.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "city",
      "readonly": ""
    },
    domProps: {
      "value": _vm.$v.form.city.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.city, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.city.$error && !_vm.$v.form.city.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.city")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group col-md-6",
    class: {
      'has-error': _vm.$v.form.state.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("state")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.state.$model,
      expression: "$v.form.state.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "state",
      "readonly": ""
    },
    domProps: {
      "value": _vm.$v.form.state.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.state, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.state.$error && !_vm.$v.form.state.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("state")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group col-md-6"
  }, [_vm.organizationType == 'Individu' ? _c('label', {
    attrs: {
      "for": "phone_number"
    }
  }, [_vm._v(_vm._s(_vm.$t("mobile-no")))]) : _vm._e(), _vm.organizationType != 'Individu' ? _c('label', {
    attrs: {
      "for": "phone_number"
    }
  }, [_vm._v(_vm._s(_vm.$t("office-no")))]) : _vm._e(), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.phone_number,
      expression: "form.phone_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number",
      "id": "phone_number"
    },
    domProps: {
      "value": _vm.form.phone_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "phone_number", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group col-md-6",
    class: {
      'has-error': _vm.$v.form.email.$error
    }
  }, [_vm.organizationType == 'Individu' ? _c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v(_vm._s(_vm.$t("email")))]) : _vm._e(), _vm.organizationType != 'Individu' ? _c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v(_vm._s(_vm.$t("office-email")))]) : _vm._e(), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "lowercase",
      rawName: "v-lowercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.email.$model,
      expression: "$v.form.email.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "email"
    },
    domProps: {
      "value": _vm.$v.form.email.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.email, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), !_vm.$v.form.email.email ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.invalid-email")) + " ")]) : _vm._e(), _vm.$v.form.email.$error && !_vm.$v.form.email.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.email")) + " ")]) : _vm._e()]), _vm.organizationType != 'Individu' ? _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    attrs: {
      "for": "website"
    }
  }, [_vm._v(_vm._s(_vm.$t("website")))]), _c('input', {
    directives: [{
      name: "lowercase",
      rawName: "v-lowercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.website,
      expression: "form.website"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.website
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "website", $event.target.value);
      }
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "form-group col-md-6",
    class: {
      'has-error': _vm.$v.form.status.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "status"
    }
  }, [_vm._v(_vm._s(_vm.$t("status")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.status.$model,
      expression: "$v.form.status.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.form.status, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v(_vm._s(_vm.$t("active")))]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v(_vm._s(_vm.$t("inactive")))])]), _vm.$v.form.status.$error && !_vm.$v.form.status.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" Status " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _vm.organizationType != 'Individu' ? _c('div', {
    staticClass: "form-group col-md-6",
    class: {
      'has-error': _vm.$v.form.company_registration_number.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "status"
    }
  }, [_vm._v(_vm._s(_vm.$t("org.reg-no")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.company_registration_number.$model,
      expression: "$v.form.company_registration_number.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.form.company_registration_number.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.company_registration_number, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.company_registration_number.$error && !_vm.$v.form.company_registration_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("org.reg-no")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]) : _vm._e(), _vm.organizationType == 'Individu' ? _c('div', {
    staticClass: "form-group col-md-6",
    class: {
      'has-error': _vm.$v.form.mykad_number.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "status"
    }
  }, [_vm._v(_vm._s(_vm.$t("mykad")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('the-mask', {
    staticClass: "form-control",
    attrs: {
      "id": "ic_number",
      "mask": ['######-##-####']
    },
    model: {
      value: _vm.$v.form.mykad_number.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.form.mykad_number, "$model", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "$v.form.mykad_number.$model"
    }
  }), _vm.$v.form.mykad_number.$error && !_vm.$v.form.mykad_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mykad")) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.organizationType != 'Awaris' && _vm.organizationType != 'Individu' ? _c('div', {
    staticClass: "form-group col-md-6",
    class: {
      'has-error': _vm.$v.form.founder_name.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "status"
    }
  }, [_vm._v(_vm._s(_vm.$t("org.founder")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.founder_name.$model,
      expression: "$v.form.founder_name.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.form.founder_name.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.founder_name, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.founder_name.$error && !_vm.$v.form.founder_name.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("org.founder")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]) : _vm._e(), _vm.organizationType != 'Awaris' && _vm.organizationType != 'Individu' ? _c('div', {
    staticClass: "form-group col-md-6",
    class: {
      'has-error': _vm.$v.form.pic_phone_number.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "pic_phone_number"
    }
  }, [_vm._v(_vm._s(_vm.$t("pic-telno")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.pic_phone_number,
      expression: "form.pic_phone_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number",
      "id": "pic_phone_number"
    },
    domProps: {
      "value": _vm.form.pic_phone_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "pic_phone_number", $event.target.value);
      }
    }
  }), _vm.$v.form.pic_phone_number.$error && !_vm.$v.form.pic_phone_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("pic-telno")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]) : _vm._e(), _vm.organizationType != 'Awaris' && _vm.organizationType != 'Bank' ? _c('div', {
    staticClass: "form-group col-md-6",
    class: {
      'has-error': _vm.$v.form.bank_account_number.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "status"
    }
  }, [_vm._v(_vm._s(_vm.$t("bank-account")))]), _vm.organizationType != 'Bank' ? _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]) : _vm._e(), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.bank_account_number.$model,
      expression: "$v.form.bank_account_number.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.$v.form.bank_account_number.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.bank_account_number, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.bank_account_number.$error && !_vm.$v.form.bank_account_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("bank-account")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]) : _vm._e(), _vm.organizationType == 'Bank' ? _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    attrs: {
      "for": "status"
    }
  }, [_vm._v(_vm._s(_vm.$t("bank-account")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.bank_account_number,
      expression: "form.bank_account_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.form.bank_account_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "bank_account_number", $event.target.value);
      }
    }
  })]) : _vm._e(), _vm.organizationType != 'Awaris' && _vm.organizationType != 'Bank' ? _c('div', {
    staticClass: "form-group col-md-6",
    class: {
      'has-error': _vm.$v.form.bank_account_holder.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "status"
    }
  }, [_vm._v(_vm._s(_vm.$t("acc-holder")))]), _vm.organizationType != 'Bank' ? _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]) : _vm._e(), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.bank_account_holder.$model,
      expression: "$v.form.bank_account_holder.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.form.bank_account_holder.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.bank_account_holder, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.bank_account_holder.$error && !_vm.$v.form.bank_account_holder.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("acc-holder")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]) : _vm._e(), _vm.organizationType == 'Bank' ? _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    attrs: {
      "for": "status"
    }
  }, [_vm._v(_vm._s(_vm.$t("acc-holder")))]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.bank_account_holder,
      expression: "form.bank_account_holder"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.bank_account_holder
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "bank_account_holder", $event.target.value);
      }
    }
  })]) : _vm._e(), _vm.organizationType != 'Awaris' && _vm.organizationType != 'Bank' ? _c('div', {
    staticClass: "form-group col-md-6",
    class: {
      'has-error': _vm.$v.form.bank_name.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "status"
    }
  }, [_vm._v(_vm._s(_vm.$t("bankname")))]), _vm.organizationType != 'Bank' ? _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]) : _vm._e(), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.bank_name.$model,
      expression: "$v.form.bank_name.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.form.bank_name.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.bank_name, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.bank_name.$error && !_vm.$v.form.bank_name.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("bankname")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]) : _vm._e(), _vm.organizationType == 'Bank' ? _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    attrs: {
      "for": "status"
    }
  }, [_vm._v(_vm._s(_vm.$t("bankname")))]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.bank_name,
      expression: "form.bank_name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.bank_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "bank_name", $event.target.value);
      }
    }
  })]) : _vm._e()]), _c('hr'), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-6",
    class: {
      'has-error': _vm.$v.form.allow_payment_gateway.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "status"
    }
  }, [_vm._v(_vm._s(_vm.$t("org.online")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.allow_payment_gateway.$model,
      expression: "$v.form.allow_payment_gateway.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.form.allow_payment_gateway, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v(_vm._s(_vm.$t("active")))]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v(_vm._s(_vm.$t("inactive")))])]), _vm.$v.form.allow_payment_gateway.$error && !_vm.$v.form.allow_payment_gateway.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("online-payment-status")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group col-md-6",
    class: {
      'has-error': _vm.$v.form.allow_cash_payment.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "status"
    }
  }, [_vm._v(_vm._s(_vm.$t("org.cash")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.allow_cash_payment.$model,
      expression: "$v.form.allow_cash_payment.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.form.allow_cash_payment, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v(_vm._s(_vm.$t("active")))]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v(_vm._s(_vm.$t("inactive")))])]), _vm.$v.form.allow_cash_payment.$error && !_vm.$v.form.allow_cash_payment.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("cash-payment-status")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group col-md-6",
    class: {
      'has-error': _vm.$v.form.allow_direct_payment.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("accept-direct-payment")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.allow_direct_payment.$model,
      expression: "$v.form.allow_direct_payment.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.form.allow_direct_payment, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v(_vm._s(_vm.$t("active")))]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v(_vm._s(_vm.$t("inactive")))])]), _vm.$v.form.allow_direct_payment.$error && !_vm.$v.form.allow_direct_payment.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("direct-payment-status")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _vm.organizationType == 'Koperasi' || _vm.organizationType == 'Kerajaan' || _vm.organizationType == 'Swasta' ? _c('div', {
    staticClass: "form-group col-md-6",
    class: {
      'has-error': _vm.$v.form.allow_atb_payment.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("accept-atb")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.allow_atb_payment.$model,
      expression: "$v.form.allow_atb_payment.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.form.allow_atb_payment, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v(_vm._s(_vm.$t("active")))]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v(_vm._s(_vm.$t("inactive")))])]), _vm.$v.form.allow_atb_payment.$error && !_vm.$v.form.allow_atb_payment.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("atb-payment-status")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]) : _vm._e()]), _c('hr'), _vm.organizationType == 'Individu' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 mb-4"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("user.info-user")))])]), _c('div', {
    staticClass: "form-group col-md-6",
    class: {
      'has-error': _vm.$v.form.username.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "username"
    }
  }, [_vm._v(_vm._s(_vm.$t("username")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('div', {
    staticClass: "input-group mb-3"
  }, [_c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text",
    attrs: {
      "id": "basic-addon1"
    }
  }, [_vm._v(_vm._s(_vm.form.code))])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.username.$model,
      expression: "$v.form.username.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number",
      "aria-describedby": "basic-addon1"
    },
    domProps: {
      "value": _vm.$v.form.username.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.username, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _vm.$v.form.username.$error && !_vm.$v.form.username.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.username")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("gender")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.gender.$model,
      expression: "$v.form.gender.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": "gender"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.form.gender, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.gender")) + " --")]), _vm._l(_vm.genders, function (gender, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": gender.split(':')[0]
      }
    }, [_vm._v(" " + _vm._s(gender.split(":")[1]) + " ")]);
  })], 2), _vm.$v.form.gender.$error && !_vm.$v.form.gender.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("gender")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_c('hr'), _c('div', {
    staticClass: "form-group col-md-12 text-right"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " "), _vm.submit ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()])])])])])])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }